import React from 'react';

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  filterIcon,
  locationIcon,
  cancleIcon,
  ActiveFilter,
} from "./assets";
import { Pagination } from "@material-ui/lab";
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        overflowX: "hidden"
      },
    }
  }
});
import Loader from '../../../components/src/Loader.web';
import "../../dashboard/src/Dashboard.css"
import { searchDefaultImage } from "../../../blocks/dashboard/src/assets"
import { webStyles } from '../../../components/src/styles';
// Customizable Area End

import PerformanceTrackerController, { Props, configJSON } from './PerformanceTrackerController';

export default class PerformanceTracker extends PerformanceTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  errorMessageDisplay = () => {
    return (
      <Box display={"none"} color={"red"} fontSize={"12px"}>
        <Typography>Failed to load data</Typography>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      currentPage,
      loaderData,
      totalCount,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Box style={webStyles.breadcrumbWrapper}>
            <Typography style={webStyles.breadcrumbItem}>{configJSON.ActiontrackerText}</Typography>
          </Box>
          <Grid container justifyContent='space-between'>
            <Typography style={webStyle.actionTrackerSubHeader}>{configJSON.ActiontrackerText}</Typography>
            <img
              src={(this.state.statusFilter.length > 0 || this.state.selectedFilters.length > 0) ? ActiveFilter : filterIcon}
              alt="filter"
              onClick={this.filterModalOpen}
              style={(this.state.statusFilter.length > 0 || this.state.selectedFilters.length > 0) ? webStyle.filterIcon : webStyle.filterDarkIcon}
              data-test-id="filterModalOpen"
            />
          </Grid>
          {!this.state.loaderData && (this.state.selectedFilters.length > 0 || this.state.statusFilter.length > 0) && (
            <Grid container style={{ marginBottom: '10px' }}>
              <Grid item data-test-id="filterGrid" style={{ display: 'contents' }}>
                {this.state.statusFilter.map((data: any, index: number) => (
                  <div style={webStyle.searchedStyle} key={index}>
                    {this.StatusNames(data)}
                    <CloseIcon
                      style={webStyle.closeIconChipStyle}
                      onClick={this.handleRemoveStatusFitlter.bind(this, data)}
                      data-test-id={`handleRemoveStatusFitlter${index}`}
                    />
                  </div>
                ))}
              </Grid>
              <Grid item data-test-id="filterGrid2" style={{ display: 'contents' }}>
                {this.state.selectedFilters.map((data: any, index: number) => (
                  <div style={webStyle.searchedStyle} key={index}>
                    {this.capitalizeFirstLetter(data[0])}
                    <CloseIcon
                      style={webStyle.closeIconChipStyle}
                      onClick={this.handleRemoveAssignedFitlter.bind(this, data)}
                      data-test-id={`handleRemoveSelectedFitlter${index}`}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
          <Loader loading={loaderData} data-test-id="loader" />
          {!this.state.loaderData && (
            <>
              <Grid container spacing={3} data-test-id="listData">
                {this.state.actionTrackerPostData.map((postData: any, index: any) => (
                  <Grid data-test-id={`postcards${[index]}`} item lg={4} xs={12} sm={6} md={6} key={index} onClick={this.viewPost.bind(this, index)}>
                    <Paper style={{ ...webStyle.gridStyle, borderColor: `${this.checkborderColor(postData.attributes.post?.risk_level_name)}` }}>
                      <Box display={"flex"} justifyContent='space-between' alignItems='center' style={webStyle.mainCardBox}>
                        <Box data-test-id={`user_name${[index]}`} onClick={() => this.goToUserProfile(postData.attributes.account_id)} display={"flex"} alignItems='center' style={{ maxWidth: '58%' }}>
                          <Avatar src={postData.attributes.supervisor_account.profile_image || searchDefaultImage} style={webStyle.profileImage} />
                          <Typography style={webStyle.supervisorName as React.CSSProperties}>
                            {postData.attributes.supervisor_account.full_name} {configJSON.CreatedthisactionText}
                          </Typography>
                        </Box>
                        <Box style={{ ...(webStyle.statusButton as React.CSSProperties), background: this.getStatusBackground(postData.attributes.status), color: this.statusTextColor(postData.attributes.status), }}>
                          {this.StatusNames(postData.attributes.status)}
                        </Box>
                      </Box>
                      <Typography style={webStyle.description as React.CSSProperties} className='action_description'>
                        {postData.attributes.description}
                      </Typography>
                      <div data-test-id={`user_name2${[index]}`} style={webStyle.userImgDiv} onClick={() => this.goToUserProfile(postData.attributes.account_id)}>
                        <Avatar src={postData.attributes.worker_account?.profile_image || searchDefaultImage} style={{ width: '24px', height: '24px' }} />
                        <Typography style={webStyle.assignedToText as React.CSSProperties}>{configJSON.AssignedtoText}: {postData.attributes.worker_account?.full_name}</Typography>
                      </div>
                      <Grid container>
                        <Typography style={webStyle.locationTypography as React.CSSProperties}>
                          <img src={locationIcon} style={webStyle.locationLogo} />
                          <span style={webStyle.locationText}>{postData.attributes.post?.location || configJSON.NoLocationText}</span>
                        </Typography>
                        <Typography style={webStyle.locationTypography as React.CSSProperties}>
                          <img src={locationIcon} style={webStyle.locationLogo} />
                          <span style={webStyle.locationText}>{postData.attributes.post?.work_location || configJSON.NoLocationText}</span>
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
                {this.state.actionTrackerPostData.length === 0 &&
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='center'
                    style={webStyle.NODataText}
                  >
                    {configJSON.NoDataAvailableText}
                  </Grid>
                }
              </Grid>
              <Grid style={{ display: "flex", justifyContent: "end" }}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={currentPage}
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={this.handlePageChange}
                  data-test-id="handlePageChange"
                />
              </Grid>
            </>
          )}
        </Wrapper>
        <Dialog maxWidth={"sm"} open={this.state.ModalOpen} style={{ overflowX: "hidden" }}>
          <div>
            <div style={webStyle.mainLabel}>
              <Typography style={webStyle.FiltersHeader}>{configJSON.FiltersText}</Typography>
              <img
                src={cancleIcon}
                alt=""
                style={webStyle.closeIcon}
                onClick={this.filterModalClose}
                data-test-id="CloseModal"
              />
            </div>
            <Grid
              container
              spacing={2}
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
            >
              <div style={webStyle.modalDiv}>
                <Typography style={webStyle.StatusPopUpText}>{configJSON.StatusText}</Typography>
                {
                  this.state.assignFilterName.length > 0 && (
                    <Typography
                      style={webStyle.statusLabel}
                      onClick={this.clearStatusFilter}
                      data-test-id="clearStatusFilter"
                    >
                      {configJSON.ClearText}
                    </Typography>
                  )
                }
              </div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {this.state.statuses.map((status, index) => (
                  <div
                    style={{
                      border: "1px solid #bbb9b9",
                      padding: "0px 13px",
                      borderRadius: "8px",
                      color: this.getStatusColor(this.state.statusFilter, status.name),
                      fontFamily: "ClotherRegular",
                      cursor: "pointer",
                      height: '39px',
                      display: 'flex',
                      alignItems: 'center',
                      background: this.getStatusBackgroundColor(
                        this.state.statusFilter,
                        status.name
                      ),
                    }}
                    key={index}
                    onClick={this.statusSelectFilter.bind(this, status.name)}
                    data-test-id={`statusSelectFilter${[index]}`}
                  >
                    <img src={this.getIcon(this.state.statusFilter, status.name)} style={{ marginRight: "10px" }} />
                    {status.label}
                  </div>
                ))}
              </div>
            </Grid>

            {this.state.assignFilterName.length > 0 && (
              <>
                <p
                  style={{
                    marginTop: " 35px",
                    borderBottom: "1px solid #dfdcdc",
                  }}
                ></p>
                <Grid container spacing={2} style={webStyle.assignedMainGrid}>
                  <div style={webStyle.assignedMainDiv}>
                    <Typography style={webStyle.StatusPopUpText}>
                      {configJSON.AssignedtoText}
                    </Typography>
                    <Typography
                      style={webStyle.statusLabel}
                      onClick={this.clearAssignedFilter}
                      data-test-id="clearAssignedFilter"
                    >
                      {configJSON.ClearText}
                    </Typography>
                  </div>
                  <div style={webStyle.assignFilterDiv as React.CSSProperties} data-test-id="assignedFilters">
                    {this.state.assignFilterName?.map((data, index) => (
                      <div
                        style={{
                          border: "1px solid #bbb9b9",
                          padding: "0px 13px",
                          borderRadius: "8px",
                          color: this.getAssignFilterColor(data),
                          fontFamily: "ClotherRegular",
                          cursor: "pointer",
                          height: '39px',
                          display: 'flex',
                          alignItems: 'center',
                          background: this.getAssignFilterBackground(data),
                        }}
                        key={index}
                        data-test-id={`assignedSelectFilter${[index]}`}
                        onClick={this.assignedSelectFilter.bind(this, data)}
                      >
                        <img src={this.getAssignIcon(this.state.assignedFilter, data)} style={{ marginRight: "10px" }} />
                        {data[0]}
                      </div>
                    )
                    )}
                  </div>
                </Grid>
              </>
            )}
            <div
              style={{
                padding: "30px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <Button
                onClick={this.ClearAllFilters}
                data-test-id="ClearAllFilters"
                variant="outlined"
                color="secondary"
                style={webStyle.btnStyle as React.CSSProperties}
              >
                {configJSON.ClearAllText}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={webStyle.btnStyleColor as React.CSSProperties}
                onClick={this.applyFilter}
                data-test-id="filterModalClose"
              >
                {configJSON.ApplyText}
              </Button>
            </div>
          </div>
        </Dialog>
        {this.errorMessageDisplay()}
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  FiltersHeader: {
    fontFamily: 'ClotherBold',
    fontSize: '24px',
    lineHeight: "24px",
    margin: '20px 0px',
  },
  StatusPopUpText: {
    fontFamily: 'ClotherBold',
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: '10px',
  },
  NODataText: {
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    height: '250px',
  },
  filterIcon: {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  },
  filterDarkIcon: {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    fill: '#2B2A29'
  },
  actionTrackerHeader: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '22px',
    paddingBottom: '17px',
    color: '#18181E',
    cursor: 'pointer'
  },
  actionTrackerSubHeader: {
    fontFamily: 'ClotherBold',
    fontSize: '26px',
    lineHeight: '18px',
    paddingBottom: '27px',
    marginTop: '17px',
  },
  locationLogo: {
    width: '14px',
    height: '14px',
    marginRight: '6px',
  },
  assignedToText: {
    color: "#A1A1A1",
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    marginLeft: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  description: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    color: '#A1A1A1',
    height: '51px',
  },
  mainCardBox: {
    paddingBottom: '12px'
  },
  supervisorName: {
    fontFamily: 'ClotherBold',
    fontSize: '16px',
    color: '#2B2A29',
    marginLeft: '14px',
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  profileImage: {
    width: '36px',
    height: '36px',
  },
  LabelStyle: {
    fontSize: "22px",
    fontWeight: 600,
  },
  statusButton: {
    border: "none",
    padding: "6px 9px",
    textAlign: "center",
    textDecoration: " none",
    display: " inline-block",
    cursor: "pointer",
    borderRadius: "19px",
    fontFamily: 'ClotherRegular',
    fontSize: "13px",
    maxWidth: '40%',
  },
  locationTypography: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    display: "flex",
    alignItems: "center",
    background: "#EEEEEE",
    borderRadius: "8px",
    padding: "7px 10px",
    whiteSpace: "nowrap",
    color: "#A1A1A1",
    marginRight: '10px',
    marginBottom: '10px',
    maxWidth: '38%',
  },
  locationText: {
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  gridStyle: {
    background: "#fff",
    padding: '15px 16px 6px 15px',
    borderRadius: "8px",
    cursor: "pointer",
    height: "90%",
    boxShadow: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  btnStyle: {
    minWidth: "160px",
    height: "50px",
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    textTransform: "none",
    color: "#8735E1",
    backgroundColor: "white",
    border: "1px solid #8735E1",
    borderRadius: "8px",
  },
  btnStyleColor: {
    minWidth: "160px",
    height: "50px",
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    textTransform: "none",
    border: "1px solid #8735E1",
    color: "white",
    backgroundColor: "#8735E1",
    borderRadius: "8px",
  },
  searchedStyle: {
    border: "1px solid #8735E1",
    padding: "6px 15px 6px 15px",
    borderRadius: " 8px",
    background: "#F7EFFF",
    color: "#8735E1",
    display: "flex",
    cursor: "pointer",
    marginRight: '12px',
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '18px',
    marginBottom: '12px',
  },
  mainLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    padding: "10px",
    paddingLeft: "20px",
  },
  closeIcon: {
    cursor: "pointer",
    height: "12px",
    width: "12px",
    marginRight: "15px",
  },
  modalDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  statusLabel: {
    fontFamily: 'ClotherBold',
    fontSize: '14px',
    lineHeight: "22px",
    color: "#8735E1",
    cursor: 'pointer'
  },
  assignedMainDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  assignedMainGrid: {
    paddingLeft: "30px",
    paddingRight: "30px",
    marginTop: "10px",
  },
  clearAssignedLabel: {
    fontWeight: 600,
    color: "#8735E1",
    cursor: "pointer",
  },
  closeIconChipStyle: {
    width: "17px",
    height: "17px",
    marginLeft: "3px",
    marginTop: '3px',
    cursor: 'pointer'
  },
  userImgDiv: {
    display: "flex",
    marginBottom: "14px",
    marginTop: '8px',
  },
  assignFilterDiv: {
    marginTop: "10px",
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    width: "100%",
  },
};

const Wrapper = withStyles({
  root: {
    background: "rgb(238, 238, 238)",
    height: "auto",
    padding: "30px",
  }
})(Grid)
// Customizable Area End
