import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  CircularProgress,
  Grid,
  styled,
  Card,
  CardContent,
  CardMedia
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { calender, lessonImage } from "./assets";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
export const configJSON = require("./config");
import Loader from "../../../components/src/Loader.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { AssessmentTestCard } from "../../../components/src/SidebarMenu.web";
import { webStyles } from "../../../components/src/styles";
// Customizable Area End

import AssessmentTestController, {
  Props,
} from "./AssessmentTestController";
import { AssessmentItemInterface, AttemptedAssessmentInterface } from "./IAssessment";

export default class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
        <Loader loading={this.state.loading} data-test-id="loader" />
          <CardWrapper>
           {!this.state.loading && (<> <Container maxWidth={"xl"}>
              <Box style={webStyles.breadcrumbWrapper}>
                <Typography style={webStyles.breadcrumbItem}>{configJSON.Learning}</Typography>
              </Box>
              <Box className="analyticTitle">
                <Typography className="mainHeading">{configJSON.Learning}</Typography>
              </Box>
              <Box className="mainBox">
                <Grid container spacing={2}>
                  <AssessmentTestCard course={this.state.courses} navigateToAssessmentDetail={this.navigateToAssessmentDetail} />
                  {this.state.courses.length === 0 &&
                    <Grid container alignItems='center' justifyContent='center' className="NODataText">
                      {configJSON.NoDataAvailableText}
                    </Grid>}
                </Grid>
              </Box>
              <Grid style={{ display: "flex", justifyContent: "end" }}>
                <Pagination
                  count={Math.ceil(this.state.totalCount / 10)}
                  page={this.state.currentPage}
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={this.handlePageChange}
                  data-test-id="handlePageChange"
                />
              </Grid>
            </Container></>)}
          </CardWrapper>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyles = {
  navBar: {
    position: "relative",
    padding: "12px 64px",
    background: "rgb(37, 0, 65)",
    color: "rgb(255, 255, 255)",
  },

  card: {
    position: "relative",
    overflow: "hidden",
    background: "linear-gradient(60deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6))",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    boxShadow: "5px 5px 15px rgb(43, 43, 43)",
  }
}
const CardWrapper = styled(Box)({
  width: "100%",
  backgroundColor: "#f7f5f5",
  padding: "30px 6px",
  boxSizing: "border-box",
  height: "100%",
  overflow: "auto",
  position: "relative",
  "& .learningHeadingWrapper": {
    display: "flex",
    gap: "10px",
    marginTop: '10px'
  },
  "& .learningSubHeadingText": {
    color: 'gray',
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "21px",
    fontFamily: "ClotherRegular",
  },
  "& .learningTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0px 20px 0px",
  },
  "& .mainHeading": {
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "25px",
    fontFamily: "ClotherBold",
  },
  "& .mainBox": {
    backgroundColor: 'white',
    maxWidth: "100%",
    padding: "16px"
  },
  "& .courseCardStyle": {
    cursor: "pointer",
    background: "#fff",
    border: "1.5px solid lightgray",
    boxShadow: "none",
    borderRadius: "8px",
  },
  "& .cardContentMainBox": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .courseCardMainHeading": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "ClotherBold",
    minHeight: "50px"
  },
  "& .cardDescription": {
    display: "-webkit-box",
    fontFamily: "ClotherRegular",
    color: "#A1A1A1",
    fontSize: "16px",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    lineHeight: "20px",
    marginBottom: "4px",
    minHeight: "60px",
    fontWeight: 'normal'
  },
  "& .trimWords": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%"
  },
  "& .imageAndText": {
    display: "flex",
    gap: 5,
    marginTop: 8,
    alignItems: "center"
  },
  "& .cardSubText": {
    fontFamily: "ClotherRegular",
    color: "#A1A1A1",
    fontSize: "14px",
    lineHeight: "1.2"
  },
  "& .analyticTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0px",
  },
  "& .NODataText": {
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    height: '250px',
  },
});
// Customizable Area End
