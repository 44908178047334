import React from "react";
import {
    Box,
    Typography,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    Button,
} from "@material-ui/core";
import { HealthImg } from "./assets";
import { profileIcon } from '../../../components/src/assets';
import RiskCardProfileController, { Props, configJSON, RiskRecordItem, MatchedGoal } from "./RiskCardProfileController.web";
import { NavigateNext } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import { webStyles } from "../../../components/src/styles";

export default class RiskCardProfile extends RiskCardProfileController {
    constructor(props: Props) {
        super(props);
    }

    loadRiskLevelsUi = (levels: RiskRecordItem[]) => {
        const level1Index = levels.findIndex(level => level.name === 'level1');
        const level2Index = levels.findIndex(level => level.name === 'level2');
        const level3Index = levels.findIndex(level => level.name === 'level3');

        // If Level 3 is created, mark Level 2 and Level 1 as created
        if (level3Index !== -1 && levels[level3Index].levelCreated) {
            if (level2Index !== -1) levels[level2Index].levelCreated = true;
            if (level1Index !== -1) levels[level1Index].levelCreated = true;
        }

        // If Level 2 is created, mark Level 1 as created
        if (level2Index !== -1 && levels[level2Index].levelCreated) {
            if (level1Index !== -1) levels[level1Index].levelCreated = true;
        }

        return (
            <Grid item lg={3} md={3} style={webStyle.valuesHeader}>
                <Box display={"flex"} alignItems={"center"} style={webStyle.valuesBox} >
                    {levels.map((levelItem: any, index: number) => (
                        <Box key={index} style={this.getLevelStyle(levelItem)}></Box>
                    ))}
                </Box>
            </Grid>
        );
    }

    getLevelStyle = (level: any) => {
        if (level.id) {
            if (level.levelCreated) {
                return webStyle.valuesFilledBox;
            } else {
                return webStyle.valuesUnFilledBox;
            }
        } else {
            return webStyle.valuesNullBox;
        }
    }

    getStatusBasedStyle = (status: string) => {
        let monthStyle: any = {};
        let textStyle: any = {};

        switch (status) {
            case configJSON.statusJustStarted:
                monthStyle = { ...webStyle.goalRank, ...webStyle.redRank };
                textStyle = { ...webStyle.goalRankText, ...webStyle.whiteRankText };
                break;

            case configJSON.statusOnTrack:
                monthStyle = { ...webStyle.goalRank, ...webStyle.yellowRank };
                textStyle = { ...webStyle.goalRankText, ...webStyle.whiteRankText };
                break;

            case configJSON.statusExceeding:
                monthStyle = { ...webStyle.goalRank, ...webStyle.greenRank };
                textStyle = { ...webStyle.goalRankText, ...webStyle.whiteRankText };
                break;

            default:
                monthStyle = { ...webStyle.goalRank, ...webStyle.whiteRank };
                textStyle = { ...webStyle.goalRankText, ...webStyle.grayRankText };
                break;
        }

        return { monthStyle, textStyle };
    }

    getGoalMonthUi = (monthlyData: MatchedGoal[]) => {
        return (
            <>
                <Grid container>
                    {
                        monthlyData?.map((data) => {
                            return (
                                <Box key={data.id} style={this.getStatusBasedStyle(data.progress || 'default').monthStyle}>
                                    <Typography style={this.getStatusBasedStyle(data.progress || 'default').textStyle}>{data.letter}</Typography>
                                </Box>
                            )
                        })
                    }
                </Grid>
            </>
        );
    }

    getLevelTitleCase = (title: string): string => {
        return title.charAt(0).toUpperCase() + title.slice(1);;
    }

    displayErrorMessage = () => {
        return (
            <Box color={"#000"} fontSize={"12px"} display={"none"}>
                <Typography>unable to load data</Typography>
            </Box>
        );
    }

    render() {
        return (
            this.state.isLoading ? (
                <Loader loading={this.state.isLoading} />
            ) : (
                <div className="my-profile">
                    <Box style={webStyle.mainContainer}>
                        <Box style={webStyles.breadcrumbWrapper}>
                            <Typography style={webStyles.breadcrumbItem} onClick={this.goToHome}>{configJSON.HomeText}</Typography>
                            <NavigateNext style={webStyles.navigateIcon} />
                            <Typography style={webStyles.breadcrumbItem} onClick={this.goToMyProfile}>{configJSON.ProfileText}</Typography>
                            <NavigateNext style={webStyles.navigateIcon} />
                            <Typography style={webStyles.breadcrumbActiveItem}>{configJSON.riskProfileCardText}</Typography>
                        </Box>
                        <Box style={webStyle.BgColor}>
                            <Typography style={webStyle.header}>{configJSON.riskProfileCardText}</Typography>
                            <Grid container style={webStyle.borderBox} alignItems="center">
                                <Grid item>
                                    <img src={HealthImg} style={webStyle.healthImg} />
                                </Grid>
                                <Grid item>
                                    <Typography style={webStyle.missionText}>{configJSON.healthMissionText}</Typography>
                                    <Typography style={webStyle.safetyText}>{configJSON.safetyText}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item style={webStyle.detailsHeader}>
                                    <Typography style={webStyle.myDetailsText}>{configJSON.MyDetailsText}</Typography>
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container style={webStyle.myDetailsBox} alignItems="center" spacing={1}>
                                        <Grid item>
                                            <img src={this.state.ProfileData?.attributes?.profile_image.length !== 0 ? this.state.ProfileData?.attributes?.profile_image : profileIcon} style={webStyle.userImage} />
                                        </Grid>
                                        <Grid item lg={10}>
                                            <Grid container>
                                                <Grid item lg={3} md={2}>
                                                    <Typography style={webStyle.users}>{configJSON.NameText}: <span style={webStyle.usersNames}>{this.state.ProfileData?.attributes?.full_name}</span></Typography>
                                                </Grid>
                                                <Grid item lg={3} md={3}>
                                                    <Typography style={webStyle.users}>{configJSON.FunctionText}: <span style={webStyle.usersNames}>{this.state.ProfileData?.attributes?.function_name}</span></Typography>
                                                </Grid>
                                                <Grid item lg={3} md={2}>
                                                    <Typography style={webStyle.users}>{configJSON.LocationText}: <span style={webStyle.usersNames}>{this.state.ProfileData?.attributes?.work_location || 'No Location'}</span></Typography>
                                                </Grid>
                                                <Grid item lg={3} md={2}>
                                                    <Typography style={webStyle.users}>{configJSON.DateText}: <span style={webStyle.usersNames}>{this.state.ProfileData?.attributes?.date_of_birth}</span></Typography>
                                                </Grid>
                                                <Grid item lg={3} md={2}>
                                                    <Typography style={webStyle.users}>{configJSON.RoleText}: <span style={webStyle.usersNames}>{this.state.ProfileData?.attributes?.role_name}</span></Typography>
                                                </Grid>
                                                <Grid item lg={3} md={2}>
                                                    <Typography style={webStyle.users}>{configJSON.EmpCodeText}: <span style={webStyle.usersNames}>{this.state.ProfileData?.attributes?.employee_id}</span></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item lg={6}>
                                    <Grid container>
                                        <Grid item style={webStyle.detailsHeader}>
                                            <Typography style={webStyle.myDetailsText}>{configJSON.MyGoalsText}</Typography>
                                        </Grid>
                                        <Grid item style={{ ...webStyle.bgColor, ...webStyle.cardsMainWrapper }}>
                                            {this.state.riskProfileGoals.length > 0 ?
                                                <Grid data-test-id="goal-container" container style={webStyle.goalsContainer}>
                                                    {this.state.riskProfileGoals.map((item: any) => (
                                                        <Grid item style={webStyle.goalsBox}>
                                                            <Typography style={webStyle.goalText}>{item.type + ' Goal'}</Typography>
                                                            <Typography style={webStyle.goalDetails}>{item.goals}</Typography>
                                                            {this.getGoalMonthUi(item.monthlyStatus)}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                :
                                                <Grid container style={webStyle.safetyContainer}>
                                                    <Grid item lg={12} md={12} style={{ ...webStyle.valuesHeader, ...{ height: '300px' } }}>
                                                        <Typography> No goals created. </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={6}>
                                    <Grid container>
                                        <Grid item style={webStyle.detailsHeader}>
                                            <Typography style={webStyle.myDetailsText}>{configJSON.RiskLevelsText}</Typography>
                                        </Grid>
                                        <Grid item style={{ ...webStyle.bgColor, ...webStyle.cardsMainWrapper }}>
                                            {this.state.riskLevels.length > 0 ?
                                                <Grid container style={webStyle.safetyContainer}>
                                                    <Grid item lg={3} md={3} style={webStyle.valuesHeader}>
                                                    </Grid>
                                                    <Grid item lg={3} md={3} style={webStyle.valuesHeader}>
                                                        <Typography align="center" style={webStyle.columnText}>{configJSON.HomeText}</Typography>
                                                    </Grid>
                                                    <Grid item lg={3} md={3} style={webStyle.valuesHeader}>
                                                        <Typography align="center" style={webStyle.columnText}>{configJSON.RoadText}</Typography>
                                                    </Grid>
                                                    <Grid item lg={3} md={3} style={webStyle.valuesHeader}>
                                                        <Typography align="center" style={webStyle.columnText}>{configJSON.workText}</Typography>
                                                    </Grid>

                                                    {this.state.riskLevels.map((item: any, index: number) => (
                                                        <>
                                                            <Grid item lg={3} md={3} style={webStyle.valuesHeader}>
                                                                <Typography data-test-id={`level-title-${index}`} style={webStyle.levelTitle}>{this.getLevelTitleCase(item.title)}</Typography>
                                                            </Grid>
                                                            {this.loadRiskLevelsUi(item.home)}
                                                            {this.loadRiskLevelsUi(item.road)}
                                                            {this.loadRiskLevelsUi(item.work)}
                                                        </>
                                                    ))}
                                                </Grid>
                                                :
                                                <Grid container style={webStyle.safetyContainer}>
                                                    <Grid item lg={12} md={12} style={{ ...webStyle.valuesHeader, ...{ height: '300px' } }}>
                                                        <Typography> No levels created. </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" style={{ marginTop: "20px" }}>
                                <Grid item>
                                    <Typography style={webStyle.radioBtnText}>{configJSON.HeyText} {this.state.ProfileData?.attributes?.full_name}, {configJSON.likeText} <span style={webStyle.darkText}>{configJSON.influencerText}</span> {configJSON.forText} <span style={webStyle.darkText}>{configJSON.TransformText}</span></Typography>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        aria-labelledby="hazard-radio-buttons-group-label"
                                        defaultValue="no"
                                        value={'yes'}
                                        name="radio-buttons-group"
                                        style={webStyle.footerRadioG as React.CSSProperties}
                                    >
                                        <FormControlLabel
                                            style={webStyle.footerRadioLabel}
                                            name="hazard"
                                            data-id="hazardFirstId"
                                            value="yes"
                                            control={<Radio />}
                                            label={
                                                <Typography
                                                    style={webStyle.formControlLabel as React.CSSProperties}
                                                >
                                                    {configJSON.yesText}
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            style={webStyle.footerRadioLabel}
                                            name="hazard"
                                            data-id="hazardSecondId"
                                            value="no"
                                            control={<Radio />}
                                            label={
                                                <Typography
                                                    style={webStyle.formControlLabel as React.CSSProperties}
                                                >
                                                    {configJSON.NoText}
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end" style={webStyle.buttons}>
                                <Button variant="outlined" style={webStyle.downloadBtn as React.CSSProperties}>{configJSON.DownloadText}</Button>
                                <Button data-test-id="follow-btn" variant="contained" style={webStyle.shareBtn as React.CSSProperties}>{configJSON.ShareText}</Button>
                            </Grid>
                        </Box>
                    </Box>
                    {this.displayErrorMessage()}
                </div >
            )
        );
    }
}

const webStyle = {
    columnText: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#2B2A29'
    },
    levelTitle: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#2B2A29',
        align: 'left',
        padding: '10px',
        '&::first-letter': {
            textTransform: 'uppercase',
        }
    },
    bgColor: {
        background: '#FFF',
        padding: '20PX',
    },
    valuesUnFilledBox: {
        width: '18px',
        height: '8px',
        borderRadius: '2px',
        background: '#D9D9D9',
        marginLeft: '4px',
        cursor: 'pointer',
    },
    valuesNullBox: {
        width: '18px',
        height: '8px',
        borderRadius: '2px',
        background: 'transparent',
        marginLeft: '4px',
    },
    valuesHeader: {
        minHeight: '68px',
        borderRight: '1px solid #EEEEEE',
        borderBottom: '1px solid #EEEEEE',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    valuesFilledBox: {
        width: '18px',
        height: '8px',
        borderRadius: '2px',
        background: '#8735E1',
        marginLeft: '4px',
        cursor: 'pointer',
    },
    valuesBox: {
        width: '72px',
        height: '16px',
        borderRadius: '2px',
        border: '0.2px solid #8735E1',
    },
    safetyContainer: {
        borderRadius: '8px',
        border: '1px solid #EEEEEE',
        background: '#FFFFFF',
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'baseline',
    },
    goalDetails: {
        fontFamily: 'ClotherRegular',
        fontSize: '15px',
        lineHeight: '20px',
        marginBottom: '14px',
        color: '#828282'
    },
    goalText: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '18px',
        marginBottom: '14px',
        color: '#2B2A29'
    },
    goalRankNormalText: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#A1A1A1'
    },
    goalRankText: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '16px',
        paddingBottom: '3px',
    },
    whiteRankText: {
        color: '#FFFFFF',
    },
    grayRankText: {
        color: '#A1A1A1',
    },
    whiteRank: {
        background: 'transparent',
        marginRight: '6px',
        border: '1px solid #A1A1A1',
        color: '#A1A1A1'
    },
    yellowRank: {
        background: '#f7cb2f',
        marginRight: '6px',
        border: '1px solid #f7cb2f'
    },
    redRank: {
        background: '#F90303',
        marginRight: '6px',
        border: '1px solid #F90303'
    },
    greenRank: {
        background: '#21C274',
        marginRight: '6px',
        border: '1px solid #21C274'
    },
    goalRank: {
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        marginRight: '6px',
        marginBottom: '6px',
        border: '1px solid #A1A1A1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    BgColor: {
        background: "rgb(238, 238, 238)"
    },
    navigateColor: {
        fill: '#DCDCDC'
    },
    mainContainer: {
        padding: "30px",
        background: "rgb(238, 238, 238)"
    },
    buttons: {
        marginTop: '50px'
    },
    radioBtnText: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#2B2A29'
    },
    darkText: {
        fontFamily: 'ClotherBold',
    },
    footerRadioG: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginTop: '-8px'
    },
    formControlLabel: {
        fontFamily: 'ClotherBold',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#2B2A29'
    },
    footerRadioLabel: {
        margin: '0 0.5rem'
    },
    goalsContainer: {
        background: '#FFFFFF',
    },
    goalsBox: {
        width: '100%',
        mainHeight: '106px',
        padding: '16px',
        borderRadius: '8px',
        border: '1px solid #EEEEEE',
        marginBottom: '16px'
    },
    usersNames: {
        fontFamily: 'ClotherBold',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#2B2A29'
    },
    users: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#2B2A29',
        marginBottom: '20px',
    },
    myDetailsText: {
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#FFFFFF',
        marginLeft: '20px',
    },
    myDetailsBox: {
        padding: '20px',
        background: '#FFFFFF',
    },
    userImage: {
        width: '100px',
        height: '101.95px',
        borderRadius: '4px',
        border: '1px solid #8735E1',
        marginRight: '12px',
    },
    detailsHeader: {
        height: '52px',
        borderRadius: '8px 8px 0px 0px',
        background: '#521978',
        marginTop: '25px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    missionText: {
        fontFamily: 'ClotherBold',
        fontSize: '22px',
        lineHeight: '26px',
        color: '#521978'
    },
    safetyText: {
        fontFamily: 'ClotherBold',
        fontSize: '22px',
        lineHeight: '26px',
        color: '#E15517'
    },
    healthImg: {
        width: '93px',
        height: '89px',
        marginRight: '21px'
    },
    borderBox: {
        width: '100%',
        height: '129px',
        borderRadius: '8px',
        border: '1px solid #521978',
        padding: '20px',
        background: '#FFFFFF'
    },
    header: {
        fontFamily: 'ClotherBold',
        fontSize: '24px',
        lineHeight: '24px',
        padding: '20px 0px 25px 0px'
    },
    noDataText: {
        fontFamily: 'ClotherBold',
        fontSize: '22px',
        height: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerCircle: {
        color: '#8735E1',
    },
    contactValue: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#2B2A29'
    },
    contactTitle: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#A1A1A1'
    },
    contactContainer: {
        padding: '19px 16px 12px 16px',
    },
    emailIcon: {
        width: '22px',
        height: '22px',
        padding: '2.75px 0px 2.75px 0px',
    },
    iconBox: {
        width: '40px',
        height: '40px',
        borderRadius: '4px',
        background: '#F8F8F8',
        marginRight: '10px',
    },
    descrption: {
        fontSize: "16px",
        fontFamily: "ClotherRegular",
        lineHeight: '20px',
        color: '#828282',
        padding: '0px 0px 18px 0px',
    },
    userNameText: {
        fontSize: "18px",
        fontFamily: "ClotherBold",
        lineHeight: '24px',
        color: '#2B2A29',
        marginLeft: '16px'
    },
    userDetails: {
        margin: '0px 0px 18px 0px'
    },
    profilePic: {
        cursor: "pointer",
        height: '46px',
        width: '46px',
    },
    viewAllText: {
        fontFamily: 'ClotherBold',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#8735E1',
        cursor: 'pointer',
        paddingBottom: '20px',
        marginTop: '22px',
    },
    recentPostTitle: {
        fontFamily: 'ClotherBold',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#2B2A29',
        paddingBottom: '20px',
        marginTop: '22px',
    },
    postList: {
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        marginRight: '20px',
    },
    shareBtn: {
        width: '180px',
        height: '46px',
        padding: '10px 60px 10px 60px',
        borderRadius: '8px',
        border: '1px solid #8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        background: '#8735E1'
    },
    downloadBtn: {
        width: '180px',
        height: '46px',
        padding: '10px 60px 10px 60px',
        borderRadius: '8px',
        border: '1px solid #8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#8735E1',
        boxShadow: 'none',
        textTransform: 'none',
        background: '#FFFFFF',
        marginRight: '15px'
    },
    followingValue: {
        fontFamily: 'ClotherBold',
        fontSize: '16px',
        lineHeight: '22px',
    },
    following: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#727272',
        marginRight: '20px'
    },
    userName: {
        fontFamily: 'ClotherBold',
        fontSize: '22px',
        lineHeight: '24px',
        paddingBottom: '14px',
    },
    role: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#727272',
        paddingBottom: '19px',
    },
    actionTrackerHeaders: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#18181E',
        margin: '0px',
    },
    currentPage: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        color: '#8735E1',
        marginLeft: '2px',
    },
    profileContainer: {
        height: '140px',
        borderRadius: '8px',
        background: '#FFFFFF',
        margin: '15px 0px 20px 0px',
    },
    avatarStyle: {
        height: '100px',
        width: '100px',
    },
    cardsMainWrapper: {
        width: '100%',
        height: '490px',
        overflowY: 'auto',
        borderRadius: "0px 0px 8px 8px"
    } as const
}
