Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.searchApiEndPoint = "bx_block_posts/posts/search_post_or_user";
exports.searchApiMethodType = "POST";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.empIDErrMsg = "Please enter valid Employee ID";
exports.fullNameErrMsg = "Please enter valid Name";
exports.MobileErrMsg = "Please enter valid Mobile Number";
exports.emailErrMsg = "Please enter valid email";
exports.genderErrMsg = "Please Select Gender";
exports.dobErrMsg = "Please Select Date of Birth";

// Posts section
exports.postsGetUrl = "/bx_block_profile/profiles/get_post_creation_data";
exports.postCreateUrl = "/bx_block_posts/posts";
exports.postsContentType = "application/json";
exports.postsApiMethodType = "GET";
exports.postsApiPostMethodType = "POST";
exports.putMethodType = "PUT";
exports.postImageUploadUrl = "/bx_block_posts/posts/upload_image_post";
exports.languages = [
  { id: '1', name: 'English' },
  { id: '2', name: 'Hindi' },
  { id: '3', name: 'Dutch' },
  { id: '4', name: 'Hungarian' },
  { id: '5', name: 'Tamil' },
  { id: '6', name: 'Malayalam' },
  { id: '7', name: 'Gujarati' },
];
exports.MonthGoalObj = [
  { id: 1, value: "J", month: "January" },
  { id: 2, value: "F", month: "February" },
  { id: 3, value: "M", month: "March" },
  { id: 4, value: "A", month: "April" },
  { id: 5, value: "M", month: "May" },
  { id: 6, value: "J", month: "June" },
  { id: 7, value: "J", month: "July" },
  { id: 8, value: "A", month: "August" },
  { id: 9, value: "S", month: "September" },
  { id: 10, value: "O", month: "October" },
  { id: 11, value: "N", month: "November" },
  { id: 12, value: "D", month: "December" },
]

// Notifications section
exports.notificationsGetUrl = "bx_block_notifications/notifications",
  exports.notificationsContentType = "application/json";
exports.notificationsApiMethodType = "GET";
exports.NoDescrpitionText = "No Descrpition";
exports.NoLocationText = "No Location";
exports.CommentsText = "Comments";
exports.LikesText = "Likes";
exports.agoText = "ago";
exports.createdText = "created this post";
exports.NoNameText = "No Name";
exports.postsAPIEndPoint = "bx_block_posts/posts/published_posts_for_feed";
exports.accountAPIEndPoint = "account_block/accounts";
exports.allFunctionsApiEndPoint = "bx_block_profile/profiles/get_all_sub_functions";
exports.SubFunctionsApiEndPoint = "bx_block_profile/profiles/get_work_locations";
exports.tagsAPIEndPoint = "bx_block_posts/report_posts/report_tags";
exports.repostPostAPIEndPoint = "bx_block_posts/report_posts"
exports.LikePostApiEndPoint = "bx_block_like_a_post/like_posts";
exports.GetLikesPostApiEndPoint = "bx_block_like_a_post/like_posts?id=1";
exports.AddCommentPostApiEndPoint = "bx_block_comments/comments";
exports.ReportText = "Report";
exports.YourImageText = "Your Image";
exports.showCommentsAPIEndPoint = "bx_block_comments/comments";
exports.ReportPostText = "Report Post";
exports.ReportCommentText = "Report Comment";
exports.ReportSubHeaderText = "Why are you reporting this post?";
exports.ReportDescriptionLable = "Couldn’t find reason? Describe your reason here";
exports.errorTextMsg = "Please enter description or at least one tag.";
exports.ReportBtnText = "Report";
exports.PostReportedText = "Post Reported";
exports.CommentReportedText = "Comment Reported";
exports.OkayBtnText = "Okay";
exports.ReportedSubHeaderText = "We will look into this matter and take necessary actions within 2 working days. Thank you for raising your concern.";
exports.reportCommentsAPIEndPoint = "bx_block_comments/report_comments"
exports.getPostDataById = "bx_block_posts/posts";
exports.home = "Home"
exports.Profile = "Profile"
exports.Edit_Profile = "Edit Profile"
exports.operationsIdentificationsEndPoint = "bx_block_profile/profiles/get_operation_identifications";
exports.getRisksLevelEndpoint = "bx_block_profile/profiles/get_risk_levels";
exports.createRiskLevelEndpoint = "bx_block_profile/profiles/create_risk_level";
exports.aiProcessErrorMessage = "Failed to process! Please try again.";
exports.myProfilePostsEnpoint = "bx_block_posts/posts/my_profile_posts";
// Customizable Area End