// Customizable Area Start
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import React from "react";
import { HomeIcon, RoadIcon, tabIconBag, riskLevel1, riskLevel2, riskLevel3 } from "./assets";
import { webStyles } from "../../../components/src/styles";
import RiskLevelsController, { Props } from "./RiskLevelsController.web";
import CongratsModalStep from "./CongratesModalStep.web";
// Customizable Area End
export default class RiskLevels extends RiskLevelsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSteps = (stepNumber: number, title: any, icon: any) => {
    const isStep1 = this.state.stepValue === 1;
    const isStep2 = this.state.stepValue === 2;
    const isStep3 = this.state.stepValue === 3;

    const isActivestep =
      (isStep1 && stepNumber === 1) ||
      (isStep2 && stepNumber === 2) ||
      (isStep3 && stepNumber === 3)

    return (
      <>
        <Box
          data-test-id="riskTypeButton"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            ...(webStyles.stepIdentifier as React.CSSProperties),
            border: isActivestep ? `2px solid var(--Purple, #8735E1)` : "none",
          }}
          onClick={() => { }}
        >
          <img src={icon} alt={`${title} icon`} />
          <Typography
            style={{
              fontWeight: isActivestep ? 700 : 400,
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Box>
      </>
    );
  };

  getLevelWrapperStyle = (level: any) => {
    const { levelSelectError } = this.state;
    if (levelSelectError) {
      return webStyle.errorLevelWrapper;
    } else {
      if (this.getLevelActiveStatus(level.id, level.levelCreated)) {
        return webStyle.activeLevelWrapper;
      } else {
        return webStyle.levelMainWrapper;
      }
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      currentRiskLevel,
      isLoading,
      progressPercentage,
      visibleLevel,
      totalLevels,
      riskLevelsCreated,
    } = this.state;

    const SemiCircularProgressBar = (value: number) => {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={value}
            size={200}
            thickness={5}
            style={{ ...webStyle.progressBar, ...{ color: '#8735E1', zIndex: 9 } }}
          />
          <CircularProgress
            variant="determinate"
            value={50}
            size={200}
            thickness={5}
            style={{ ...webStyle.progressBar, ...{ color: '#FAF3FD', position: 'absolute' } }}
          />
          <Box sx={webStyle.progressPercentage}>
            <Typography variant="h4" component="p">
              {`${Math.round(value * 2)}%`}
            </Typography>
          </Box>
        </Box>
      );
    };

    const getLevelIcon = (level: string) => {
      switch (level.toLowerCase()) {
        case 'level1':
          return riskLevel1;

        case 'level2':
          return riskLevel2;

        case 'level3':
          return riskLevel3;

        default:
          break;
      }
    }
    return (
      <>
        <Box style={{ width: "100%" }}>
          <Grid container style={{ marginTop: "-301px" }}>
            <Grid container item xs={12} justifyContent="center">
              <Box display="flex" justifyContent="center" alignItems="center" marginBottom={"-50px"}>
                {SemiCircularProgressBar(progressPercentage / 2)}
              </Box>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Typography style={webStyle.mainTitle}>
                U1st Wellbeing Champions Card
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Typography style={webStyle.description}>
                Excellent! Now add risk levels by sharing your applicable risk categories on Road, Home & Work. <br />
                For your wellbeing, Use U1st to keep track on your risk levels.
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: " -20px",
                }}
              >
                {this.renderSteps(1, "Home", HomeIcon)}
                <Box
                  style={{
                    width: "3.5rem",
                    height: "1px",
                    backgroundColor: "rgb(135 53 225)",
                    marginTop: "56px",
                  }}
                />
                {this.renderSteps(2, "Road", RoadIcon)}
                <Box
                  style={{
                    width: "3.5rem",
                    height: "1px",
                    backgroundColor: "rgb(135 53 225)",
                    marginTop: "56px",
                  }}
                />
                {this.renderSteps(3, "Work", tabIconBag)}
              </Grid>
            </Grid>
          </Grid>
          <Box style={webStyle.contentWrapper}>
            {currentRiskLevel && !isLoading &&
              <Grid container>
                <Grid container item xs={12} justifyContent="center">
                  <Typography style={webStyle.riskLevelTitle}>{currentRiskLevel?.title}</Typography>
                </Grid>
                {currentRiskLevel?.levels?.map((level: any, index: number) => (
                  <>
                    {level?.id &&
                      <Grid data-test-id={`level-wrapper${index}`} key={level.id} container item xs={12} style={{ marginBottom: '30px' }} onClick={() => this.selectLevel(level.id)}>
                        <Box style={this.getLevelWrapperStyle(level)}>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            padding={'10px'}
                            alignItems={'center'}
                            borderBottom={this.getLevelActiveStatus(level.id, level.levelCreated) ? '2px solid #8735E1' : '2px solid #EEEEEE'}
                          >
                            <Box display={'flex'} flexDirection={'row'}>
                              <img src={getLevelIcon(level.name)} alt="risk-level" />
                              <Typography data-test-id={`levelText${index}`} style={webStyle.riskLevelHeading}>{this.formatLevelText(level.name)}</Typography>
                            </Box>
                            <Box>
                              <Radio
                                checked={this.getLevelActiveStatus(level.id, level.levelCreated)}
                                onChange={() => { }}
                                value={level.id}
                                name="radio-button-level-1"
                              />
                            </Box>
                          </Box>
                          <Box padding={'10px'} alignItems={'center'}>
                            <Typography style={webStyle.riskLevelDescription}>{level.description}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    }
                  </>
                ))}
              </Grid>
            }
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            padding="0.75rem"
            style={{ marginTop: "1rem", marginLeft: "-9px" }}
          >
            <Button
              style={{
                ...webStyles.transButton,
                width: "15%",
                borderRadius: "8px",
              }}
              data-test-id="previousButton"
              onClick={this.previousStep}
            >
              <Typography style={webStyles.buttonStyle as React.CSSProperties}>
                {" "}
                Previous
              </Typography>
            </Button>
            <Button
              style={{
                ...webStyles.fullButton,
                width: "15%",
                borderRadius: "8px",
              }}
              data-test-id="nextButton"
              variant="contained"
              type="submit"
              onClick={this.nextStep}
            >
              <Typography style={webStyles.buttonStyle as React.CSSProperties}>
                {visibleLevel === totalLevels ? 'Submit' : 'Next'}
              </Typography>
            </Button>
          </Box>
          {riskLevelsCreated && (
            <CongratsModalStep
              navigation={this.props.navigation}
              activeStep={this.props.activeStep}
              activeStepState={4}
            />
          )}
        </Box>
        {isLoading &&
          <Backdrop style={{ color: "#fff", zIndex: 99 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const webStyle = {
  contentWrapper: {
    marginBottom: "10px",
    borderBottom: "1px solid #edebeb",
    paddingBottom: "90px",
    marginTop: "40px",
  },
  selectedStyle: {
    position: "absolute",
    left: "73%",
    top: "4px",
  },
  BoxStyle: {
    display: "flex",
    width: "7vw",
    height: "7vw",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
  },
  TitleName: {
    fontWeight: 400,
    textAlign: "center",
  },
  mainTitle: {
    fontFamily: "ClotherBold",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "center" as const,
    color: '#2B2A29',
    marginBottom: '30px',
  },
  description: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "center" as const,
    color: '#A1A1A1',
    marginBottom: '60px',
  },
  progressBar: {
    transform: 'rotate(-180deg)',
    backgroundColor: '#transparent',
    '& circle': {
      strokeLinecap: 'round',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'red',
      zIndex: 1
    }
  },
  progressPercentage: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-50px',
    color: '#18181E',
    fontFamily: "ClotherBold",
  },
  riskLevelTitle: {
    fontFamily: "ClotherBold",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "16px",
    textAlign: "center" as const,
    color: '#2B2A29',
    marginBottom: '30px',
    textTransform: 'capitalize' as const,
  },
  levelMainWrapper: {
    width: '100%',
    minHeight: '90px',
    border: '2px solid #EEEEEE',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  } as const,
  activeLevelWrapper: {
    width: '100%',
    minHeight: '90px',
    border: '2px solid #8735E1',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  } as const,
  errorLevelWrapper: {
    width: '100%',
    minHeight: '90px',
    border: '2px solid red',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  } as const,
  riskLevelDescription: {
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    color: '#2B2A29',
  },
  riskLevelHeading: {
    fontFamily: "ClotherBold",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "16px",
    color: '#2B2A29',
    marginLeft: '20px',
  }
};
// Customizable Area End
