import React from "react";

import {
    Box,
    Input,
    Button,
    Typography,
    // Customizable Area Start
    styled,
    Grid,
    FormHelperText,
    Select,
    MenuItem,
    InputBase,
    InputAdornment,
    Switch,
    TextField,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext, KeyboardArrowDown } from "@material-ui/icons";
import { calendarLogo, upload } from "./assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import ScheduleInspectionController, { Props, ScheduleInspectionData, PersonListOptions } from "./ScheduleInspectionController";
import { TextFieldProps } from '@material-ui/core/TextField';

type RenderInputParams = TextFieldProps & {
    InputProps: {
        className?: string;
        endAdornment?: React.ReactNode;
        startAdornment?: React.ReactNode;
        ref?: React.Ref<HTMLDivElement>;
    };
};
type FormikTouched<T> = {
    [K in keyof T]?: boolean;
};

type FormikErrors<T> = {
    [K in keyof T]?: string;
};

const today = new Date();

// Customizable Area End


export default class ScheduleInspection extends ScheduleInspectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    CustomInputDate = (
        value: Date | null | undefined,
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
        onChange: (field: string, value: string) => void,
    ) => {
        return (
            <Input
                placeholder="Select Date"
                value={value}
                onClick={onClick}
                fullWidth={true}
                disableUnderline
                data-test-id="textInputTest"
                name="dateTest"
                onChange={(event) => onChange("inspectionDate", event.target.value)}
                defaultValue={""}
                endAdornment={
                    <div>
                        <InputAdornment position="end">
                            <img src={calendarLogo} className="calenderLogoStyle" />
                        </InputAdornment>
                    </div>
                }
            />
        );
    };

    scheduleInspectionForm = (
        setFieldValue: (field: string, value: string | null | PersonListOptions) => void,
        values: ScheduleInspectionData,
        handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
        handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void,
        submitForm: () => Promise<void>,
        touched: FormikTouched<ScheduleInspectionData>,
        errors: FormikErrors<ScheduleInspectionData>
    ) => {
        const { locations, departments, machines, errorMessageImage1, errorMessageImage2, accountData, assignPersonLoading } = this.state;
        return (
            <>
                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Equipment Name</FormHelperText>
                    <Input
                        name="equipmentName"
                        data-test-id="equipment_name"
                        className="inputField"
                        value={values.equipmentName}
                        disableUnderline
                        placeholder="Equipment Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                    />
                    <Typography className="errorStyle">
                        {errors.equipmentName && touched.equipmentName && errors.equipmentName}
                    </Typography>
                </Grid>

                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Equipment Number</FormHelperText>
                    <Input
                        name="equipmentNumber"
                        data-test-id="equipment_number"
                        className="inputField"
                        value={values.equipmentNumber}
                        disableUnderline
                        placeholder="Equipment Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                    />
                    <Typography className="errorStyle">
                        {errors.equipmentNumber && touched.equipmentNumber && errors.equipmentNumber}
                    </Typography>
                </Grid>
                <Grid className="inputBlock" md={6} xs={12}>
                    <Box className="locationStyleBox">
                        <Typography className="inputLabel">
                            Location
                        </Typography>
                        <Typography className="inputLabel">
                            <Switch size="small" color="primary" />
                            Auto detect Location
                        </Typography>
                    </Box>
                    <Select
                        data-test-id="location"
                        className="inputField"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        variant="outlined"
                        name="location"
                        IconComponent={KeyboardArrowDown}
                        value={values.location}
                        displayEmpty
                        renderValue={
                            values.location
                                ? undefined
                                : () => (
                                    <div style={{ color: "#DCDCDC", fontSize: 18 } as React.CSSProperties}>
                                        Location Details
                                    </div>
                                )
                        }
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        input={<InputBase />}
                    >
                        {locations.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {this.getOptionForMenu(option)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography className="errorStyle">
                        {errors.location && touched.location && errors.location}
                    </Typography>
                </Grid>
                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Machine/Tool<span>*</span></FormHelperText>
                    <Select
                        data-test-id="machines"
                        className="inputField"
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        variant="outlined"
                        name="machines"
                        IconComponent={KeyboardArrowDown}
                        displayEmpty
                        renderValue={
                            values.machines
                                ? undefined
                                : () => (
                                    <div style={{ color: "#DCDCDC", fontSize: 18 } as React.CSSProperties}>
                                        Select Machine/Tool
                                    </div>
                                )
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        MenuProps={{
                            anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                            },
                            getContentAnchorEl: null,
                        }}
                        input={<InputBase />}
                    >
                        {machines.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {this.getOptionForMenu(option)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography className="errorStyle">
                        {errors.machines && touched.machines && errors.machines}
                    </Typography>
                </Grid>

                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Department</FormHelperText>
                    <Select
                        data-test-id="department"
                        className="inputField"
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        variant="outlined"
                        name="department"
                        IconComponent={KeyboardArrowDown}
                        displayEmpty
                        renderValue={
                            values.department
                                ? undefined
                                : () => (
                                    <div style={{ color: "#DCDCDC", fontSize: 18 } as React.CSSProperties}>
                                        Select Department
                                    </div>
                                )
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        MenuProps={{
                            anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                            },
                            getContentAnchorEl: null,
                        }}
                        input={<InputBase />}
                    >
                        {departments.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {this.getOptionForMenu(option)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography className="errorStyle">
                        {errors.department && touched.department && errors.department}
                    </Typography>
                </Grid>
                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Email ID</FormHelperText>
                    <Input
                        name="email"
                        data-test-id="email"
                        className="emailInputField"
                        value={accountData}
                        disableUnderline
                        disabled
                        placeholder="Please enter email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                    />
                </Grid>
                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Inspection Date</FormHelperText>
                    <DatePicker
                        className="inputField"
                        scrollableYearDropdown
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        dateFormat="dd/MM/yyyy"
                        dateFormatCalendar="MMMM"
                        data-test-id="inspection_date"
                        name="inspectionDate"
                        placeholderText="Select Date"
                        wrapperClassName="date-picker"
                        customInput={this.CustomInputDate(values.inspectionDate, () => { }, setFieldValue)}
                        popperClassName="example-datepicker-class"
                        minDate={today}
                        selected={values.inspectionDate}
                        onChange={(date) => {
                            this.handleInspectionDateChange(date, setFieldValue)
                        }}
                        onBlur={handleBlur}
                    />
                    <Typography className="errorStyle">
                        {errors.inspectionDate && touched.inspectionDate && errors.inspectionDate}
                    </Typography>
                </Grid>
                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Assign to</FormHelperText>
                    <Autocomplete
                        options={this.getAssignPersonOptionsList()}
                        loading={assignPersonLoading}
                        noOptionsText=""
                        popupIcon={<KeyboardArrowDown />}
                        data-test-id="assign_to"
                        id="assign_to"
                        getOptionLabel={(option) => `${option?.label}`}
                        onChange={(event, newValue: PersonListOptions | null) => setFieldValue("assignTo", newValue)}
                        onInputChange={(event, newValue) => this.handleAssignToPerson(newValue, setFieldValue)}
                        renderInput={(params: RenderInputParams) => (
                            <TextField
                                {...params}
                                id="assign_to"
                                placeholder="Employee Name"
                                variant="outlined"
                            />
                        )}
                    />
                    <Typography className="errorStyle">
                        {errors.assignTo && touched.assignTo && errors.assignTo}
                    </Typography>
                </Grid>
                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Upload Image1<span>*</span></FormHelperText>
                    <Button component="label" className="uploadImage">
                        <div className="upload-img">
                            <img
                                src={this.state.image1 || upload}
                                alt="upload image"
                                className="uploadedImg"
                            />
                        </div>
                        {!this.state.image1 && (
                            <>
                                <div className="uploadImageFont">Upload Image</div>
                            </>
                        )}
                        <input
                            data-test-id="upload_image1"
                            type="file"
                            name="image1"
                            accept="image/png, image/jpeg"
                            onChange={(event) => {
                                this.handleImage1Change(event, setFieldValue);
                            }}
                            onBlur={handleBlur}
                        />
                    </Button>
                    <Typography className="errorStyle">
                        {errorMessageImage1}
                        {errors.image1 && touched.image1 && errors.image1}
                    </Typography>
                </Grid>
                <Grid className="inputBlock" md={6} xs={12}>
                    <FormHelperText className="inputLabel">Upload Image2</FormHelperText>
                    <Button component="label" className="uploadImage">
                        <div className="upload-img">
                            <img
                                src={this.state.image2 || upload}
                                alt="upload image"
                                className="uploadedImg"
                            />
                        </div>
                        {!this.state.image2 && (
                            <>
                                <div className="uploadImageFont">Upload Image</div>
                            </>
                        )}
                        <input
                            data-test-id="upload_image2"
                            type="file"
                            name="image2"
                            accept="image/png, image/jpeg"
                            onChange={(event) => {
                                this.handleImage2Change(event, setFieldValue);
                            }}
                            onBlur={handleBlur}
                        />
                    </Button>
                    <Typography className="errorStyle">
                        {errorMessageImage2}
                    </Typography>
                </Grid>
            </>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Grid container>
                <Wrapper>
                    <Box className="headingWrapper">
                        <Typography className="subHeadingText" data-test-id="navigate_to_home" onClick={this.handleNavigateToHome}>Home</Typography>
                        <Typography className="subHeadingText">
                            <NavigateNext className="navigateNext" style={{ fill: '#DCDCDC' }} />
                        </Typography>
                        <Typography className="activeHeadingText">
                            Schedule an Inspection
                        </Typography>
                    </Box>
                    <Box className="mainTitle">
                        <Typography className="mainHeading">Schedule an Inspection</Typography>
                    </Box>

                    <Formik
                        initialValues={
                            {
                                equipmentName: "",
                                equipmentNumber: "",
                                location: "",
                                department: "",
                                machines: "",
                                email: this.state.accountData,
                                inspectionDate: null,
                                assignTo: {
                                    label: "",
                                    value: ""
                                },
                                image1: "",
                                image2: ""
                            } as ScheduleInspectionData
                        }
                        validationSchema={this.state.inputSchema}
                        onSubmit={(values, actions) => {
                            this.scheduleInspectionApiCall(values)
                        }}
                    >
                        {({
                            handleChange,
                            submitForm,
                            errors,
                            setFieldTouched,
                            touched,
                            values,
                            setFieldValue,
                            handleBlur,
                        }) => {
                            return (
                                <>
                                    <Box className="mainBox">
                                        <Grid md={12} className="mainGrid">
                                            {this.scheduleInspectionForm(
                                                setFieldValue,
                                                values,
                                                handleBlur,
                                                handleChange,
                                                submitForm,
                                                touched,
                                                errors)
                                            }
                                        </Grid >
                                    </Box>
                                    <Box className="scheduleButtonBox">
                                        <Button
                                            type="submit"
                                            disabled={this.state.disableSubmit}
                                            className="ScheduleButtonStyle"
                                            onClick={submitForm}
                                        >
                                            Schedule
                                        </Button>
                                        <Button
                                            className="ScheduleButtonStyleHid"
                                            onClick={() => this.scheduleInspectionApiCall(values)}
                                            data-test-id="schedule_button"
                                        ></Button>
                                    </Box>
                                </>)
                        }}
                    </Formik>
                    {this.state.loading && (
                        <Box data-test-id={"activityLoader"} className="loader-outer">
                            <Box className="loader-inner">
                                <CircularProgress />
                            </Box>
                        </Box>
                    )}
                </Wrapper>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    backgroundColor: "#f7f5f5",
    padding: "30px",
    boxSizing: "border-box",
    height: "calc(100vh - 100px)",
    overflow: "auto",
    position: "relative",
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .loader-outer": {
        position: "absolute",
        height: "125%",
        left: 0,
        bottom: 0,
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .headingWrapper": {
        display: "flex",
        marginTop: '10px'
    },
    "& .mainBox": {
        backgroundColor: 'white',
        width: "100%",
        margin: "15px 0px"
    },
    "& .mainGrid": {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 30px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }

    },
    "& .inputBlock": {
        maxWidth: '49%',
        "@media (max-width: 600px)": {
            maxWidth: '100%',

        }
    },
    "& .activeHeadingText": {
        fontWeight: 700,
        color: "#8735E1",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        leadingTrim: "both",
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
    },
    "& .inputLabel": {
        color: 'var(--Black, #18181E)',
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'ClotherRegular',
        marginBottom: '5px'
    },
    "& .react-datepicker-wrapper": {
        display: 'flex',
    },
    "& .uploadImageFont": {
        fontSize: '0.875rem',
        color: '#A1A1A1',
        fontFamily: 'ClotherRegular'
    },
    "& .uploadedImg": {
        maxWidth: '5rem',
        maxHeight: '5rem',
        objectFit: 'contain'
    },
    "& .inputField": {
        fontSie: '18px',
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        height: '50px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .emailInputField": {
        fontSie: '18px',
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'lightgray',
        height: '50px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .MuiInputBase-root": {
        fontSize: "18px"
    },
    "& .uploadImage": {
        borderRadius: '8px',
        textTransform: 'capitalize',
        border: '1px solid lightgray',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        height: '150px',
        padding: '10px 15px',
        width: '100%',
        "& .MuiButton-label": {
            diplay: 'flex',
            flexDirection: 'column',
        },
    },
    "& .errorStyle": {
        color: "#F30202",
        fontSize: "1rem",
        marginBottom: "0.5rem",
    },
    "& .mainTitle": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
    },
    "& .subHeadingText": {
        fontWeight: 700,
        color: '#18181E',
        fontSize: "16px",
        lineHeight: "22px",
        cursor: "pointer",
        fontFamily: "ClotherRegular",
    },
    "& .scheduleButtonBox": {
        display: "flex",
        flexDirection: 'row-reverse',
    },
    "& .locationStyleBox": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .calenderLogoStyle": {
        width: 28,
        height: 28,
        cursor: 'pointer'
    },
    "& .mainHeading": {
        margin: "20px 0",
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "25px",
        fontFamily: "ClotherBold",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: '#fff'
    },
    '& .MuiSelect-select': {
        borderRadius: '0.5rem'
    },
    "& .MuiSwitch-root": {
        width: "40px",
        height: "30px",
        margin: "0px 5px 5px 0px"
    },
    "& .MuiSwitch-switchBase": {
        color: "#fff",
        bottom: 0,
        "& .Mui-checked ": {
            color: "white",
        },
        "& .Mui-checked .MuiSwitch-trackcked": {
            backgroundColor: "#8735e1",
        },
    },
    "& .MuiAutocomplete-input:first-child": {
        height: "14px",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
    },
    "& .ScheduleButtonStyleHid": {
        display: "none"
    },
    "& .ScheduleButtonStyle": {
        border: "1px solid #8735E1",
        textTransform: "capitalize",
        padding: "15px 35px",
        fontSize: "18px",
        fontWeight: 600,
        height: "45px",
        lineHeight: "20px",
        color: "white",
        backgroundColor: "#8735E1",
        borderRadius: "8px",
        marginTop: "10px",
        "@media(max-width:601px)": {
            lineHeight: "15px",
            height: "45px"
        },
    },
    "& *::-ms-input-placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "18px"
    },
    "& input::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "18px"
    },
    "& *::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "18px"
    },
})
// Customizable Area End
